
import Vue from 'vue';
import { mapGetters } from 'vuex';
import HeaderWithLogo from '@/components/UI/MainBody/HeaderWithLogo.vue';
import HeaderFilters from '@/components/UI/MainBody/HeaderFilters.vue';
import SearchListResults from '@/components/UI/MainBody/SearchComponents/SearchListResults.vue';
import SearchTags from '@/components/UI/MainBody/SearchComponents/SearchTags.vue';
import { ModifiedRecipe, Tag } from '~/types';
import { WithContext, ItemList } from 'schema-dts';
import config from '~/core/config/portal.config';
import formatRecipes from '../core/utils/formatRecipes';

interface Data {
  id: string;
  offset: number;
  loading: boolean;
  refetch: boolean;
  recipes: any;
  config: typeof config;
}

interface Computed {
  searchTags: Tag[];
  results: ModifiedRecipe[];
  searchTerm: string | null;
  totalNumberOfRecipesAvailable: number;
  searchResults: ModifiedRecipe[];
}

interface Methods {
  scroll: () => void;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    HeaderWithLogo,
    HeaderFilters,
    SearchListResults,
    SearchTags,
  },
  data() {
    return {
      id: '',
      offset: 0,
      loading: false,
      refetch: false,
      recipes: null,
      config,
    };
  },
  computed: {
    ...mapGetters({
      searchTags: 'searchTags',
      searchResults: 'searchResults',
      searchTerm: 'searchTerm',
      totalNumberOfRecipesAvailable: 'totalNumberOfRecipesAvailable',
    }),
    results() {
      return this.recipes ? formatRecipes(this.recipes.items) : this.searchResults;
    },
  },
  async fetch() {
    this.refetch = !!(process.client && !this.refetch && this.$route.query.tags) || this.refetch;

    if (process.client && this.results.length > 0 && !this.refetch) {
      return;
    }

    this.recipes = await this.$store.dispatch('getRecipesWithSearch');
  },
  jsonld() {
    if (this.results.length === 0) {
      return null;
    }
    const { meta } = this.config;

    const listItems: ItemList['itemListElement'] = this.results.map((recipe, index) => {
      return {
        '@type': 'ListItem',
        position: ++index,
        url: `${meta.metaUrl}/retsept/${recipe.externalId}/${recipe.slug}`,
      };
    });

    const pageJsonld: WithContext<ItemList> = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: listItems,
    };
    return pageJsonld;
  },
  watch: {
    async '$route.query'() {
      if (process.client) {
        window.scrollTo(0, 0);
      }
      this.refetch = true;
      await this.$fetch();
      this.refetch = false;
    },
  },
  methods: {
    async scroll() {
      const docElement = document?.documentElement;
      const bottomOfWindow = docElement?.scrollTop + window?.innerHeight + 150 > docElement?.offsetHeight;
      const offset = this.results.length;

      if (bottomOfWindow && offset < this.totalNumberOfRecipesAvailable) {
        if (!this.loading) {
          try {
            this.loading = true;
            await this.$store.dispatch('getRecipesWithSearch', { offset });
          } catch (error) {
            this.$store.commit('setError', true);
            this.$notify({ title: String(this.$t('words.error')), type: 'error' });
          } finally {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }
        }
      }
    },
  },
  mounted() {
    const customerState = this.$store.state.piano.isLoggedIn ? 'logged_in' : 'logged_out';
    this.$store.commit('adForm/setPageAdsSettings', { mkw: ['channel_frontpage', 'retseptiveeb_frontpage', customerState] });
    document?.addEventListener('scroll', this.scroll);

    if (this.searchResults.length === 0 && this.recipes) {
      this.$store.commit('setSearchResults', this.recipes);
      this.recipes = null;
    }
  },
  beforeDestroy() {
    document?.removeEventListener('scroll', this.scroll);
  },
});
