
import Vue from 'vue';
import { ModifiedRecipe } from '~/types';

import RecipeFront from './RecipeFront.vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';

interface Props {
  showHeader: boolean;
  results: ModifiedRecipe[];
  searchTerm: string | null;
  hasSearchTags: boolean;
  totalNumberOfRecipesAvailable: number;
}

interface Computed {
  disableAd: boolean;
}

interface Data {
  wideSplitter: number;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    RecipeFront,
    AdFormBanner,
  },
  data() {
    return {
      wideSplitter: this.$route.name === 'recipe' ? 11 : 8,
    };
  },
  props: {
    totalNumberOfRecipesAvailable: Number,
    hasSearchTags: Boolean,
    showHeader: { type: Boolean, default: false },
    results: { type: Array, default: () => [] },
    searchTerm: { type: String },
  },
  computed: {
    disableAd() {
      return !this.$store.state.adForm.disableAdsCondition.adFree;
    },
  },
});
