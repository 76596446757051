
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ModifiedRecipe } from '~/types';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';

//import Rating from '../Rating.vue';

interface Props {
  recipe: ModifiedRecipe;
}

interface Methods {
  favouriteAction: () => void;
}
interface Computed {
  token: boolean;
  isFavourite: boolean;
  favouriteRecipesIds: string[];
  preparationTimeUnit: string;
}
export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    PianoCustomerStateHandler,
    //Rating,
  },
  props: {
    recipe: Object,
  },
  computed: {
    ...mapGetters(['favouriteRecipesIds', 'token']),
    isFavourite() {
      return this.favouriteRecipesIds.includes(this.recipe.id);
    },
    preparationTimeUnit() {
      if (this.recipe) {
        const { preparationTimeUnit } = this.recipe;
        switch (preparationTimeUnit) {
          case 'h':
            return 't';
          case 'min':
            return 'm';
          default:
            return preparationTimeUnit;
        }
      }
      return '';
    },
  },
  methods: {
    async favouriteAction() {
      this.$store.commit('toggleModal', { modal: 'center', centerType: 'favourite', id: this.recipe.id });
    },
  },
});
