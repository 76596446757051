
import Vue from 'vue';
import { mapGetters } from 'vuex';
import getRecipes from '~/core/modules/services/getRecipes';
import { Recipe, Tag } from '~/types';

interface Props {
  tags: Tag[];
}

interface Methods {
  searchRecipes: (tagId: string) => void;
  setSearchTags: (tagId: string) => void;
}

interface Computed {
  searchTags: string[];
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    tags: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['searchTags']),
  },
  methods: {
    async searchRecipes(tagId) {
      await getRecipes({ tagId: [tagId] }).then((result: { data: { items: Recipe[] } }) => this.$store.commit('setSearchResults', result.data));
    },
    setSearchTags(tag) {
      this.$store.commit('setSelectedTags', [tag]);
    },
  },
});
