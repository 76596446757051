
import Vue from 'vue';
import { mapGetters } from 'vuex';

import GoBackBtn from './GoBackBtn.vue';
import FilterBtn from './FilterBtn.vue';

interface Props {
  showGoBackBtn: boolean;
  showFilterBtn: boolean;
  saveRecipeClickAction: number;
}

interface Methods {
  clearAllFilters: () => void;
  goBack: () => void;
}

interface Computed {
  prevRoute: any;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    GoBackBtn,
    FilterBtn,
  },
  props: {
    saveRecipeClickAction: Number,
    showGoBackBtn: {
      type: Boolean,
      default: false,
    },
    showFilterBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['prevRoute']),
  },
  methods: {
    clearAllFilters() {
      this.$store.dispatch('clearAllFilters');
    },
    goBack() {
      if (this.prevRoute && this.prevRoute !== 'login') {
        this.$router.back();
        return;
      }
      this.$router.push({ path: '/', query: this.$route.query });
    },
  },
});
